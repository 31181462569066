import { Checkbox as MuiCheckbox } from "@material-ui/core";
import { ReactComponent as CheckIcon } from "assets/images/filter_check.svg";
import { ReactComponent as CheckedIcon } from "assets/images/filter_checked.svg";

const Checkbox = (props) => (
  <MuiCheckbox
  style={{padding:'.3rem'}}
    icon={<CheckIcon style={{ width: "15px", height: "15px" }} />}
    checkedIcon={<CheckedIcon style={{ width: "15px", height: "15px" }} />}
    disableRipple
    {...props}
  />
);

export default Checkbox;
