import React, { useContext, useState } from "react";
import styles from "./InnerCommentStyle.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/comment-close.svg";
import ProfileIcon from "../../../assets/images/comment-profile.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/edit-comment.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete-comment.svg";

import { Box, Button, Snackbar, TextField } from "components";
import {
  CircularProgress,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import { convertSecondsToHMS } from "utils/videoUtils";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: ".5rem",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: ".5rem",
    },
  },
}));

const useStylesForTextField = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      color: "white",
      fontSize: "0.7rem",
    },
  },
});

const roundValue = (time) => {
  const validTime = Number(time);
  if (!isNaN(validTime) && validTime >= 0) {
    const roundedTime = Math.round(validTime);
    return roundedTime;
  } else {
    console.warn("Invalid time value provided:", time);
  }
};

function InnerComment({
  approval,
  id,
  comments,
  videRef,
  handleSetComment,
  isVideoPause,
  name,
  handleClose,
  getAllComment,
  refresh
}) {
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState("");

  const startEditing = (id, comment) => {
    setEditingCommentId(id);
    setEditedCommentText(comment);
  };

  const saveComment = (id) => {
    handleEditComment(id, editedCommentText);
    setEditingCommentId(null);
  };

  const cancelEditing = () => {
    setEditingCommentId(null);
    setEditedCommentText("");
  };

  const {
    dispatch,
    state: { showLoader },
  } = useContext(GlobalContext);
  const classes = useStyles();
  const classesForTextField = useStylesForTextField();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleApproveRejectVide = async (status) => {
    storeHandler(actions.SHOW_LOADER, true);

    try {
      await AuthServices.changeVideoStatus({ id, status });
      storeHandler(actions.SHOW_LOADER, false);
      videRef?.current?.pause();
      refresh()
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleDeleteComment = async (id) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await AuthServices.deleteVideoComment({ id });
      storeHandler(actions.SHOW_LOADER, false);
      getAllComment();
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleEditComment = async (id, comment) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await AuthServices.editVideoComment({ id, comment });
      storeHandler(actions.SHOW_LOADER, false);
      getAllComment();
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleAddComment = async () => {
    const currentTime = videRef?.current?.currentTime();
    if (comment.length === 0) {
      setErrorMessage("Comment cannot be empty.");
      return;
    } else if (comment.length > 300) {
      setErrorMessage("Comment cannot exceed 300 characters.");
      return;
    } else {
      setErrorMessage("");
    }
    storeHandler(actions.SHOW_LOADER, true);

    try {
      const payload = {
        video_id: id,
        time_sec: roundValue(currentTime),
        comment,
      };
      await AuthServices.addVideoComment(payload);
      setComment("");
      storeHandler(actions.SHOW_LOADER, false);
      getAllComment();
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const seekToTime = (time) => {
    if (videRef.current) {
      videRef?.current?.currentTime(time);
      videRef?.current?.pause();
      console.log(`Seeked to ${time} seconds`);
    }
  };

  return (
    <div className={`${styles.commentBody}`}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={1}
      >
        <Typography
          style={{
            fontSize: "1.3rem",
            fontWeight: "300",
            textTransform: "capitalize",
          }}
        >
          {name}
        </Typography>
        <CloseIcon
          onClick={() => handleClose(false)}
          style={{
            width: ".8rem",
            cursor: "pointer",
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={"1rem"}
        className="mb-2 py-3"
        style={{ borderBottom: "1px solid #4F4F4F" }}
      >
        {(approval === true || approval === null) && (
          <Button
            disabled={approval === true}
            onClick={(e) => {
              handleApproveRejectVide(true);
            }}
            style={{
              padding: ".3rem 1rem",
              borderRadius: "2rem",
              fontSize: ".8rem",
              fontWeight: "200",
              backgroundColor: "black",
              color: "white",
              boxShadow: "none",
              width: "5rem",
            }}
          >
            {approval === true ? "Approved" : "Approve"}
          </Button>
        )}

        {(approval === false || approval === null) && (
          <Button
            disabled={approval === false}
            variant="contained"
            onClick={() => handleApproveRejectVide(false)}
            style={{
              padding: ".3rem 1rem",
              width: "5rem",
              borderRadius: "2rem",
              fontSize: ".8rem",
              fontWeight: "200",
              backgroundColor: "var(--error-main)",
              color: "white",
              boxShadow: "none",
            }}
          >
            {approval === false ? "Rejected" : "Reject"}
          </Button>
        )}
      </Stack>

      <Stack direction="column" height={"100%"} style={{ overflowY: "scroll" }}>
        {!!comments?.length && (
          <Stack className={`${styles.allComment}`}>
            {comments?.map(
              ({ id, comment, time_sec, added_by, comment_date, profile }) => (
                <Stack
                  className={`${styles.comment}`}
                  key={id}
                  onClick={() => seekToTime(time_sec)}
                >
                  <Stack direction="row" gap=".5rem" width="100%" mb={"1rem"}>
                    <Stack alignItems="center" gap=".2rem">
                      <img
                        src={profile || ProfileIcon}
                        alt="profile"
                        width={"40px"}
                        height={"40px"}
                        style={{ objectFit: "cover", borderRadius: "50%" }}
                        onError={(e) => {
                          e.target.src = ProfileIcon;
                        }}
                      />
                      <Typography variant="body2" style={{ fontSize: ".6rem" }}>
                        {convertSecondsToHMS(time_sec, false)}
                      </Typography>
                    </Stack>

                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      gap=".5rem"
                      width="100%"
                      className={`${styles.comment_box} `}
                    >
                      {editingCommentId === id ? (
                        <TextField
                          value={editedCommentText}
                          onChange={(e) => setEditedCommentText(e.target.value)}
                          multiline
                          maxRows={10}
                          className={classesForTextField.root}
                        />
                      ) : (
                        <Typography
                          variant="caption"
                          style={{ fontSize: ".65rem" }}
                        >
                          {comment}
                        </Typography>
                      )}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack direction="column">
                          <Typography
                            variant="body2"
                            style={{ fontSize: ".5rem", color: "gray" }}
                          >
                            {added_by}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ fontSize: ".5rem", color: "gray" }}
                          >
                            {comment_date
                              ? moment(comment_date).format("MM/DD/YYYY")
                              : "-"}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          gap=".5rem"
                          alignItems={"center"}
                        >
                          {editingCommentId === id ? (
                            <>
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  saveComment(id);
                                }}
                                variant="contained"
                                disableRipple={true}
                                style={{
                                  marginRight: "3rem",
                                  padding: ".2rem",
                                  fontSize: ".65rem",
                                  fontWeight: "200",
                                  backgroundColor: "black",
                                  color: "white",
                                  boxShadow: "none",
                                }}
                              >
                                Post
                              </Button>
                              <CloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  cancelEditing();
                                }}
                                style={{
                                  width: ".5rem",
                                }}
                              />
                            </>
                          ) : (
                            <EditIcon
                              className={
                                !(editingCommentId === id) && styles.EditIcon
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                startEditing(id, comment);
                              }}
                              style={{
                                width: ".9rem",
                              }}
                            />
                          )}
                          <DeleteIcon
                            className={
                              !(editingCommentId === id) && styles.DeleteIcon
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteComment(id);
                            }}
                            style={{
                              width: ".9rem",
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )
            )}
          </Stack>
        )}
      </Stack>

      <div className="p-1 mt-3">
        <TextField
          placeholder="Add Comment.."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onFocus={() => videRef?.current?.pause()}
          classes={{ root: classes.root }}
          variant="outlined"
          fullWidth
          InputProps={{
            disableUnderline: true,
            style: {
              backgroundColor: "#4F4F4F",
              border: "none",
              color: "white",
            },
            startAdornment: (
              <InputAdornment position="start">
                {isVideoPause && (
                  <Button
                    variant="contained"
                    disableRipple={true}
                    style={{
                      padding: ".2rem",
                      fontSize: ".8rem",
                      fontWeight: "200",
                      backgroundColor: "white",
                      color: "black",
                      boxShadow: "none",
                      cursor: "not-allowed",
                    }}
                  >
                    {convertSecondsToHMS(
                      videRef?.current?.currentTime(),
                      false
                    )}
                  </Button>
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  disabled={!isVideoPause}
                  onClick={handleAddComment}
                  variant="contained"
                  disableRipple={true}
                  style={{
                    padding: ".3rem",
                    fontSize: ".8rem",
                    fontWeight: "200",
                    backgroundColor: "black",
                    color: "white",
                    boxShadow: "none",
                  }}
                >
                  Post
                </Button>
              </InputAdornment>
            ),
          }}
          helperText={errorMessage}
        />
      </div>

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
      {showLoader && (
        <Box className={styles.loader_box}>
          <div className={styles.loader}>
            <CircularProgress />
          </div>
        </Box>
      )}
    </div>
  );
}

export default InnerComment;
