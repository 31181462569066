import { Typography } from "@material-ui/core";
import { Box } from "components";
import React from "react";
import {ReactComponent as Logo} from 'assets/images/regami_logo.svg'
import { Stack } from "@mui/system";

export const Help = () => {
  return (
    <Stack direction='column' style={{background:'black', borderRadius:'.5rem', border:'1px solid gray'}} alignItems='center' className="p-4">
      <Box className='mb-3'>
        <Logo />
      </Box>
      <Typography variant="h6" style={{color:'white'}}>
        Build Version: {process.env.REACT_APP_VERSION}{" "}
      </Typography>
      <Typography variant="h6" style={{color:'white'}}>
        Date: {process.env.REACT_APP_BUILD_DATE}{" "}
      </Typography>
    </Stack>
  );
}

export default Help;
