import React, { useContext, useEffect, useMemo, useState } from "react";
import VideoJS from "./VideoJS";
import videojs from "video.js";
import CustomSeekBar from "./CustomSeekBar";
import CustomButton from "./CustomButton";
import defaultThumbnail from "assets/images/videothumbnail.png";
import forward from "assets/images/skip-forward.svg";
import reload from "assets/images/forward.svg";
import backward from "assets/images/skip-backward.svg";
import OuterComment from "./outer-comment-box/OuterComment";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";
import { Snackbar } from "components";
import InnerComment from "./inner-comment-box/InnerComment";

const sortArrayByProperty = (array, key) => {
  if (!Array.isArray(array)) {
    console.error("First argument must be an array.");
    return [];
  }

  if (typeof key !== "string" || key.trim() === "") {
    console.error("Key must be a non-empty string.");
    return [];
  }

  if (array.length > 0 && typeof array[0] !== "object") {
    console.error("Array must contain objects.");
    return [];
  }

  return array.slice().sort((a, b) => {
    const valueA = Number(a[key]);
    const valueB = Number(b[key]);

    const validValueA = isNaN(valueA) ? 0 : valueA;
    const validValueB = isNaN(valueB) ? 0 : valueB;

    return validValueA - validValueB;
  });
};

const VideoPlayer = ({
  video,
  thumbnail,
  approval,
  id,
  name,
  outerComment = true,
  refresh
}) => {
  const [show, setShow] = React.useState(false)
  const playerRef = React.useRef(null);
  const videoContainerRef = React.useRef(null);
  const [comments, setComments] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  //--start

  const [isVideoPause, setIsVideoPause] = useState(false);
  const { dispatch } = useContext(GlobalContext);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  //Get all the comments--start

  const handleGetComments = async () => {
    // storeHandler(actions.SHOW_LOADER, true);

    try {
      const { data } = await AuthServices.getVideoComment(id);
      const sortedList = sortArrayByProperty(data, "time_sec");
      setComments(sortedList);
      // storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      // storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };
  //Get all the comments--end

  const storeHandler = (type, payload) => dispatch({ type, payload });

  useEffect(() => {
    setComments([]);
    handleGetComments();
  }, [id]);

  //After this only related for vide player...
  const videoJsOptions = useMemo(
    () => ({
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      muted: true,
      sources: [
        {
          src: video,
          type: "video/mp4",
        },
      ],
      poster: thumbnail || defaultThumbnail,
    }),
    [video, thumbnail]
  );

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      if (videoContainerRef.current) {
        videoContainerRef.current.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
  };

  const handlePlayerReady = (player) => {
    if (player) {
      playerRef.current = player;

      player.on("ready", () => {
        console.log("Player is ready");
        setShow(true)
      });

      player.on("waiting", () => {
        console.log("player is waiting");
      });

      player.on("dispose", () => {
        setShow(false)
        playerRef.current = null;
        console.log("player will dispose");
      });

      player.on("play", () => {
        setIsVideoPause(false);
      });

      player.on("pause", () => {
        setIsVideoPause(true);
      });

      //This is for full screen---start
      const fullscreenButton = player.controlBar.getChild("fullscreenToggle");
      if (fullscreenButton) {
        fullscreenButton.dispose();

        const customButton = videojs.getComponent("Button");
        const myButton = new customButton(player, {
          text: "",
          name: "CustomFullScreenButton",
        });

        myButton.addClass("vjs-fullscreen-control");
        myButton.addClass("vjs-control");
        myButton.addClass("vjs-button");

        const iconElement = document.createElement("span");
        iconElement.className = "vjs-icon-placeholder";
        myButton.el().appendChild(iconElement);

        const textElement = document.createElement("span");
        textElement.className = "vjs-control-text";
        textElement.innerText = "Fullscreen";
        myButton.el().appendChild(textElement);

        const customFullScreenFunction = () => {
          console.log("Custom fullscreen function called");
          toggleFullscreen();
        };

        myButton.on("click", customFullScreenFunction);

        player.controlBar.addChild(myButton);
      }
      //This is for full screen----end

      // Add comment button---start
      const customButton = player.addChild(
        "CustomButton",
        { customFunction: handleOpen },
        8
      );
      const controlBar = player.controlBar.el();
      const children = controlBar.children;
      const lastChild = children[children.length - 1];

      if (lastChild) {
        controlBar.insertBefore(customButton.el(), lastChild);
      } else {
        controlBar.appendChild(customButton.el());
      }
      // Add comment button---endd

      //progress bar and preview img---start
      const progressControl = player.controlBar.getChild("progressControl");
      if (progressControl) {
        console.log("Removing existing ProgressControl:");
        player.controlBar.removeChild(progressControl);

        const progressControlIndex = 7;
        player.controlBar.addChild(
          "CustomSeekBar",
          { comments, url: video },
          progressControlIndex
        );
      } else {
        console.warn("ProgressControl not found");
      }

      //progress bar and preview img---end

      //adding the fast forward and hd img--start
      let image = document.createElement("img");
      image.setAttribute("src", reload);
      image.setAttribute("class", "fhd-indicator mr-2 cursor");

      image.addEventListener("click", () => {
        player?.currentTime(player?.currentTime(0));
      });

      let backwardIcon = document.createElement("img");
      backwardIcon.setAttribute("src", backward);
      backwardIcon.setAttribute("class", "fhd-indicator mr-2 cursor");

      backwardIcon.addEventListener("click", () => {
        player?.currentTime(player?.currentTime() - 10);
      });

      let forwardIcon = document.createElement("img");
      forwardIcon.setAttribute("src", forward);
      forwardIcon.setAttribute("class", "fhd-indicator mr-2 cursor");

      forwardIcon.addEventListener("click", () => {
        player?.currentTime(player?.currentTime() + 10);
      });

      document
        .getElementsByClassName("vjs-control-bar")[0]
        .appendChild(backwardIcon);
      document
        .getElementsByClassName("vjs-control-bar")[0]
        .appendChild(forwardIcon);
      document.getElementsByClassName("vjs-control-bar")[0].appendChild(image);

      //adding the fast forward and hd img--end
    }
  };

  React.useEffect(() => {
    if (playerRef.current && show) {
      const player = playerRef.current;
      const customSeekBar = player.controlBar.getChild("CustomSeekBar");

      if (customSeekBar) {
        customSeekBar.updateComments(comments);
      }
    }
  }, [comments]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const customButton = document.querySelector(".vjs-custom-button");
      customButton.style.display = "none";

      if (customButton) {
        if (document.fullscreenElement) {
          customButton.style.display = "block";
        } else {
          customButton.style.display = "none";
          setOpen(false);
        }
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <>
      <div ref={videoContainerRef}>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
        <div className="CHILD-TEST">
          {open && (
            <InnerComment
              approval={approval}
              id={id}
              comments={comments}
              videRef={playerRef}
              handleSetComment={setComments}
              isVideoPause={isVideoPause}
              name={name}
              handleClose={setOpen}
              getAllComment={handleGetComments}
              refresh={refresh}
            />
          )}
        </div>
      </div>
      {outerComment && show && (
        <OuterComment
          approval={approval}
          id={id}
          comments={comments}
          videRef={playerRef}
          handleSetComment={setComments}
          isVideoPause={isVideoPause}
          name={name}
          getAllComment={handleGetComments}
          refresh={refresh}
        />
      )}

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </>
  );
};

export default VideoPlayer;
