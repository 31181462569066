// import axios from "axios";

// const httpClient = axios.create({
//   baseURL: process.env.REACT_APP_REST_API_URL,
//   headers: {
//     "Content-Type": "multipart/form-data",
//     "Access-Control-Allow-Origin": "*",
//   },
// });

// const onSuccess = (response) => Promise.resolve(response);
// const onError = async ({ response }) => {
//   try {
//     // Ensure `response` and `response.data` are defined
//     const { data, status } = response || {};
//     const { message } = data || {};

//     // Check if the error message indicates token expiration
//     if (status === 401 || message === "Token has expired") {
//       // Optionally call a refresh token service
//       // const newToken = await AuthServices.refreshToken();

//       // Clear local storage and reload the page
//       localStorage.clear();
//       window.location.reload();

//       // Log the response data for debugging
//       console.log(data);
//     }

//     // Reject the promise with the response to propagate the error
//     return Promise.reject(response);
//   } catch (error) {
//     // Handle any errors that occur during the execution of onError
//     console.error("An error occurred in the onError handler:", error);

//     // Optionally reject with the error if needed
//     return Promise.reject(error);
//   }
// };

// httpClient.interceptors.request.use((req) => {
//   req.headers = {};
//   if (req.url.indexOf("/video/delete") > -1) {
//     req.headers["Content-Type"] = "application/json";
//   }
//   if (req.url === "/refresh/token") {
//     req.headers["Authorization"] = `Bearer ${localStorage.getItem(
//       "refresh_token"
//     )}`;
//   } else {
//     req.headers["Authorization"] = `Bearer ${localStorage.getItem(
//       "access_token"
//     )}`;
//   }
//   return req;
// });
// httpClient.interceptors.response.use(onSuccess, onError);

// export default httpClient;

import axios from "axios";
import AuthServices from "./services/auth-services";
import { getErrorMessage } from "utils/validator";
const axiosInstance = axios.create();

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

const getAuthToken = async () => {
  return await AuthServices.refreshToken();
};

const logoutUser = async () => {
  try {
    localStorage.clear();
    window.location.href = "/";
  } catch (error) {
    getErrorMessage(error)
  }
};

const onSuccess = (response) => Promise.resolve(response);
const onError = ({ response }) => {
  const originalRequest = response?.config;

  if (response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    return getAuthToken()
      .then(async ({ access_token }) => {
        localStorage.setItem("access_token", access_token);
        originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
        return axiosInstance(originalRequest);
      })
      .catch((err) => {
        getErrorMessage(err);
        if (err.response?.status) {
          err.data = {
            message: "Token expired",
          };
          localStorage.clear();
          setTimeout(() => (window.location.href = "/"), 3000);
        }

        return Promise.reject(err);
      });
  }

  if (response?.status === 422) {
    logoutUser();
  }

  if (response?.status === 500) {
    response.data = {
      message: "Internal Server Error",
    };
    logoutUser();
  }

  return Promise.reject(response);
};
httpClient.interceptors.request.use((req) => {
  req.headers = {};
  if (req.url === "/create?func=refresh_token") {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "refresh_token"
    )}`;
  } else {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
  }
  return req;
});

httpClient.interceptors.response.use(onSuccess, onError);

export default httpClient;
