import { useState, useEffect, useContext } from "react";
import styles from "./freelancer-info.module.scss";
import Switch from "@material-ui/core/Switch";
import avatar from "assets/images/default.svg";
import callIco from "assets/images/call.svg";
import locationIco from "assets/images/location.svg";
import dateIco from "assets/images/date-ico.svg";
import mailIco from "assets/images/email-ico.svg";
import NoVideo from "assets/images/video-placeholder.svg";
import yetToSync from "assets/images/video-yet-sync.svg";
import synced from "assets/images/video-synced.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import videoIcon from "assets/images/video-icon.svg";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";

import { InputField, ConfirmDialog, Checkbox } from "components";
import EditFreelancer from "./edit-freelancer/edit-freelancer";
import { ReactComponent as DeleteIcon } from "assets/images/delete-outline.svg";
import { useHistory } from "react-router-dom";
import VideoCard from "./video-card/video-card";
import moment from "moment";
import { Paper, Tooltip, Typography } from "@material-ui/core";
import ButtonWithIcon from "components/IconButton/icon-button";
import { Stack } from "@mui/material";
import { checkBoolean } from "utils/videoUtils";
import FreelancerFilterPopover from "./freelancer-filter-opover/FreelancerFilterPopover";
import FreelancerSortPopover from "./freelancer-sort-popover/FreelancerSortPopover";
import { ReactComponent as DownloadIcon } from "assets/images/bulk_download.svg";
import { ReactComponent as TrashIcon } from "assets/images/bulk_delete.svg";
import { ReactComponent as GreenCheckIcon } from "assets/images/green_checkbox.svg";
import { ReactComponent as CheckIcon } from "assets/images/check.svg";
import { getErrorMessage } from "utils/validator";

const downloadVideos = async (videoUrls) => {
  videoUrls.forEach((url) => {
    try {
      window.open(url, "_blank");
    } catch (error) {
      console.error(`Failed to open ${url}:`, error);
    }
  });
};

const FreeLancerInfo = () => {
  const {
    dispatch,
    state: { isAdmin, userId },
  } = useContext(GlobalContext);

  const IS_ADMIN = checkBoolean(isAdmin);

  const [open, setOpen] = useState(false);
  const [freelancerDetail, setFreelancerDetail] = useState();
  const [videoStatus, setVideoStatus] = useState();
  const history = useHistory();

  const [isActive, setIsActive] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isBulkSelect, setIsBulkSelect] = useState(false);
  const [statusList, setStatus] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDownloadDialog, setOpenDownlaodDialog] = useState(false);

  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);

  const [openAccountDeletePrompt, setOpenAccountDeletePrompt] = useState(false);

  const [unselectAll, setUnSelectAll] = useState(false);

  const [totalDeletedVideos, setTotalDeletedVideos] = useState(0);

  const [profileImg, setProfileImg] = useState();

  const [videoLists, setVideoLists] = useState([]);

  const timelineFormatDate = (date) => {
    if (moment(date, moment.ISO_8601, true).isValid()) {
      return moment(date).format("MM/DD/YYYY HH:mm");
    }
    return "-";
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason, showPrompt) => {
    setOpen(false);
  };

  const getFreelancerDetails = async () => {
    try {
      storeHandler(actions.SHOW_LOADER, true);
      const { profile_details, video_stats, video } = userId.admin
        ? await AuthServices.getAdminDetail(userId.id)
        : await AuthServices.getFreelancerDetails(userId.id);
      const profInfo = { ...profile_details, video };
      profInfo.video = profInfo?.video?.map((item, i) => ({
        ...item,
        date: `${new Date(item.date).toDateString()}  ${new Date(
          item.date
        ).getHours()}:${new Date(item.date).getMinutes()}`,
      }));
      setFreelancerDetail(profInfo);
      setVideoStatus(video_stats);
      setIsActive(!!profile_details?.status);
      setVideoLists(video);
      storeHandler(actions.SHOW_LOADER, false);
      setProfileImg(profInfo.profile);
    } catch (err) {
      getErrorMessage(err);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const getUserTimeLine = async (id) => {
    try {
      const { data } = await AuthServices.getUserTimeLine(id);
      setStatus(data);
    } catch (err) {
      getErrorMessage(err);
    }
  };

  useEffect(() => {
    getFreelancerDetails();
    getUserTimeLine(userId?.id);
  }, []);

  const toggleActive = async (checked) => {
    const { admin, id } = userId;

    try {
      storeHandler(actions.SHOW_LOADER, true);

      if (admin) {
        checked
          ? await AuthServices.activateAdmin(id)
          : await AuthServices.deactivateAdmin(id);
      } else {
        checked
          ? await AuthServices.activateFreelancer(id)
          : await AuthServices.deactivateFreelancer(id);
      }
      setIsActive(checked);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const selectallVideos = () => {
    if (selectAll) {
      setSelectAll(!selectAll);
      setSelectedVideos([]);
    } else {
      setSelectAll(!selectAll);
      const ids = sortedUsers?.map((item) => item.id) || [];
      setSelectedVideos(ids);
    }
  };

  const onConfirm = () => {
    deleteVideos();
    setOpenDialog(false);
  };

  const deleteVideos = async (url) => {
    if (!selectedVideos?.length) return;

    storeHandler(actions.SHOW_LOADER, true);
    try {
      await AuthServices.deleteVideo({ id: selectedVideos });
      storeHandler(actions.SHOW_LOADER, false);
      setOpenDeletePrompt(true);
      getFreelancerDetails();
      setUnSelectAll(true);
      setSelectAll(false);
      setTotalDeletedVideos(url ? 1 : selectedVideos?.length);
      setSelectedVideos([]);
      getUserTimeLine(userId?.id);
      !url && udpateBulkSelect();
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const onConfirmDialog = () => {
    setOpenConfirmDialog(false);
    toggleActive(!isActive);
  };

  const onDownloadConfirm = async () => {
    if (!selectedVideos?.length) return;

    setOpenDownlaodDialog(false);
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const data = await AuthServices.generateDownloadURL({
        id: selectedVideos,
      });
      const { urls } = data || {};
      downloadVideos(urls);

      udpateBulkSelect();
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      console.log(error);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const onAccountDeleteConfirm = async () => {
    const { admin, id } = userId;
    try {
      storeHandler(actions.SHOW_LOADER, true);

      if (admin) {
        await AuthServices.activateAdmin(id);
      } else {
        await AuthServices.deleteFreelancer(id);
      }
      storeHandler(actions.SHOW_LOADER, false);
      setOpenAccountDeletePrompt(false);
      history.push("/contractors");
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const openPopup = () => {
    setOpenConfirmDialog(true);
  };

  const udpateBulkSelect = () => {
    setIsBulkSelect(!isBulkSelect);
    if (isBulkSelect) {
      clearSelection();
      setSelectAll(false);
    }
  };

  const clearSelection = () => {
    setSelectedVideos([]);
    setUnSelectAll(true);
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  //filter
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleOpenFilterPopover = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  //filter

  //Sort
  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleOpenSortPopover = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  //Sort

  //implementaion

  const [sortCriteria, setSortCriteria] = useState("");
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  };

  const handleFilterChange = (filter) => {
    setFilterCriteria((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((item) => item !== filter);
      } else {
        return [...prev, filter];
      }
    });
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
  };

  function roleFilteredUsers(data, inp) {
    const approved = inp.includes("approved");
    const rejected = inp.includes("rejected");
    const pending = inp.includes("pending");

    return data.filter((item) => {
      let statusCondition = true;
      let qaUserCondition = true;

      // Apply status condition
      if (approved && !rejected) {
        statusCondition = item.approval === true;
      } else if (rejected && !approved) {
        statusCondition = item.approval === false;
      } else if (!rejected && !approved) {
        statusCondition =
          item.approval === false ||
          item.approval === true ||
          item.approval === null;
      } else if (!rejected && !approved && pending) {
        statusCondition = item.approval === null;
      } else {
        statusCondition = item.approval !== null;
      }

      // Return true if both conditions are satisfied
      return statusCondition && qaUserCondition;
    });
  }

  const filteredUsers = roleFilteredUsers(videoLists, filterCriteria).filter(
    (user) =>
      user.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user.phone?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user.zipcode?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user.address?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user.account_id?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const sortedUsers = filteredUsers.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    console.log(sortCriteria);
    if (sortCriteria === "dec") {
      return nameB.localeCompare(nameA);
    } else if (sortCriteria === "acc") {
      return nameA.localeCompare(nameB);
    } else if (sortCriteria === "accd") {
      return moment(a.date).diff(moment(b.date));
    } else if (sortCriteria === "decd") {
      return moment(b.date).diff(moment(a.date));
    }

    return 0;
  });
  // implementaion

  return (
    <div className="pt-4">
      <Paper
        style={{
          borderRadius: "1rem",
          boxShadow:
            "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
          border: "none",
        }}
        variant="outlined"
        className={`${styles.freelanceInfoHeader} d-flex justify-content-between align-items-center flex-md-row flex-column`}
      >
        <Stack
          direction={"row"}
          gap={".5rem"}
          alignItems="center"
          className={!IS_ADMIN && styles.header}
        >
          <Tooltip title={freelancerDetail?.name || ""} arrow>
            <Typography
              variant="h4"
              className={styles.typography_container}
              style={{
                fontSize: "2.3rem",
                fontWeight: "600",
                marginRight: "2rem",
              }}
            >
              {freelancerDetail?.name || ""}
            </Typography>
          </Tooltip>

          {IS_ADMIN && (
            <ButtonWithIcon
              disableRipple
              endIcon={
                <FiberManualRecordIcon
                  style={{ color: isActive ? "green" : "var(--error-main)" }}
                />
              }
            >
              Active
            </ButtonWithIcon>
          )}
          {IS_ADMIN && (
            <Switch
              name="checkedA"
              color="primary"
              size="small"
              checked={isActive}
              onClick={openPopup}
            />
          )}
          {IS_ADMIN && (
            <div className="pl-3">
              <ButtonWithIcon
                disableRipple
                startIcon={<EditIcon />}
                onClick={handleClickOpen}
              >
                Edit Contractor
              </ButtonWithIcon>
            </div>
          )}
          <div className="pl-5">
            <InputField
              value={searchQuery}
              variant="outlined"
              placeholder="Search by video name "
              withAdronment={true}
              onChange={(event) => handleSearchChange(event.target.value)}
            />
          </div>
        </Stack>

        {IS_ADMIN && (
          <ButtonWithIcon
            disableRipple
            startIcon={<DeleteIcon />}
            onClick={() => setOpenAccountDeletePrompt(true)}
          >
            Delete
          </ButtonWithIcon>
        )}
      </Paper>

      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        className="p-2 py-3 "
      >
        <Stack
          direction={"row"}
          gap={".2rem"}
          alignItems="center"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/contractors")}
        >
          <KeyboardBackspaceIcon />
          <Typography variant="subtitle1">Back</Typography>
        </Stack>

        <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
          Account ID:{" "}
          <span style={{ color: "var(--error-main)" }}>
            {freelancerDetail?.account_id || ""}
          </span>
        </Typography>
      </Stack>

      <div className="d-flex flex-wrap">
        <div className={`flex-grow-1 ${styles.infoWrap}`}>
          <div className="d-flex flex-wrap">
            <div
              className={`${styles.userDetails} d-flex justify-content-between flex-grow-1 flex-wrap flex-lg-row flex-column`}
            >
              <div
                className="d-flex"
                style={{
                  background: "black",
                  borderRadius: ".3rem",
                  padding: ".8rem",
                  minWidth: "18rem",
                  maxWidth: "18rem",
                }}
              >
                <div className={`${styles.avatar}`}>
                  <img
                    src={profileImg || avatar}
                    alt={"avatar"}
                    loading="lazy"
                  />
                </div>
                <div className="d-flex flex-column justify-content-between pl-2">
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={callIco} alt="call" className="mr-2" />
                    <span className={`${styles.info}`}>
                      {freelancerDetail?.phone || "-"}
                    </span>
                  </p>
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={mailIco} alt="call" className="mr-2" />
                    <span className={`${styles.info}`}>
                      {freelancerDetail?.email || "-"}
                    </span>
                  </p>
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={dateIco} alt="location" className="mr-2" />

                    <span className={`${styles.info}`}>
                      {freelancerDetail?.date_joining
                        ? moment(freelancerDetail?.date_joining).format(
                            "MM/DD/YYYY"
                          )
                        : "-"}
                    </span>
                  </p>
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={locationIco} alt="location" className="mr-2" />
                    <span className={`${styles.info}`}>
                      {freelancerDetail?.address || "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex-grow-1 d-flex justify-content-lg-end  justify-content-center align-item-end  flex-wrap">
                <div
                  className={`${styles.videoInfoCard} p-3 mr-3 my-2 my-lg-0`}
                >
                  <Typography>Videos Captured</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    p={".3rem"}
                    alignItems="center"
                  >
                    <Typography variant="h2">
                      {videoStatus?.captured}
                    </Typography>
                    <img
                      src={videoIcon}
                      alt="video"
                      className={`${styles.videoIcon}`}
                    />
                  </Stack>
                </div>

                <div
                  className={`${styles.videoInfoCard} p-3 mr-3 my-2 my-lg-0`}
                >
                  <Typography>Videos Accepted</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    p={".3rem"}
                    alignItems="center"
                  >
                    <Typography variant="h2">
                      {videoStatus?.Accepted}
                    </Typography>
                    <img
                      src={synced}
                      alt="video"
                      className={`${styles.videoIcon}`}
                    />
                  </Stack>
                </div>

                <div className={`${styles.videoInfoCard} p-3 my-2 my-lg-0`}>
                  <Typography>Videos Rejected</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    p={".3rem"}
                  >
                    <Typography variant="h2">
                      {videoStatus?.Rejected}
                    </Typography>
                    <img
                      src={yetToSync}
                      alt="video"
                      className={`${styles.videoIcon}`}
                    />
                  </Stack>
                </div>
              </div>
            </div>
            {!userId.admin && (
              <>
                <div
                  style={{
                    width: "100%",
                    padding: ".5rem 0",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    my="1rem"
                  >
                    {!!sortedUsers?.length && (
                      <Typography variant="h5">Videos</Typography>
                    )}

                    {!!sortedUsers?.length && (
                      <Stack direction="row" gap="1.5rem">
                        {isBulkSelect && (
                          <Stack direction="row" alignItems="center" gap={0.5}>
                            <Checkbox
                              checkedIcon={
                                <GreenCheckIcon
                                  style={{ width: "15px", height: "15px" }}
                                />
                              }
                              size="small"
                              checked={
                                selectedVideos?.length === sortedUsers?.length
                              }
                              onChange={selectallVideos}
                              style={{ transform: "scale(1.2)" }}
                            />
                            <Typography
                              style={{ fontWeight: "500", cursor: "pointer" }}
                              variant="subtitle1"
                              onClick={udpateBulkSelect}
                            >
                              Select All
                            </Typography>

                            {isBulkSelect && !!selectedVideos?.length && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={"1rem"}
                                pl={"1rem"}
                              >
                                <DownloadIcon
                                  style={{ width: "25px", cursor: "pointer" }}
                                  onClick={() => setOpenDownlaodDialog(true)}
                                />
                                <TrashIcon
                                  style={{ width: "25px", cursor: "pointer" }}
                                  onClick={() => setOpenDialog(true)}
                                />
                              </Stack>
                            )}
                          </Stack>
                        )}

                        {!isBulkSelect && (
                          <ButtonWithIcon
                            disableRipple
                            startIcon={<CheckIcon />}
                            onClick={udpateBulkSelect}
                          >
                            Select All
                          </ButtonWithIcon>
                        )}

                        <ButtonWithIcon
                          disableRipple
                          startIcon={<SortIcon />}
                          onClick={handleOpenSortPopover}
                        >
                          Sort
                        </ButtonWithIcon>
                        <FreelancerSortPopover
                          anchorEl={sortAnchorEl}
                          handleClose={handleSortClose}
                          handleSortChange={handleSortChange}
                          check={sortCriteria}
                        />

                        <ButtonWithIcon
                          disableRipple
                          startIcon={<FilterIcon />}
                          onClick={handleOpenFilterPopover}
                        >
                          Filter
                        </ButtonWithIcon>
                        <FreelancerFilterPopover
                          anchorEl={filterAnchorEl}
                          handleClose={handleFilterClose}
                          handleFilterChange={handleFilterChange}
                          check={filterCriteria}
                        />
                      </Stack>
                    )}
                  </Stack>
                </div>
                {!userId.admin && !sortedUsers?.length && (
                  <div className="text-center flex-grow-1">
                    <img
                      src={NoVideo}
                      alt="no video"
                      className={`${styles.noVideo}`}
                    />
                    <p className="txt-lg txt-primary my-0">
                      Oops There is no Recorded Video
                    </p>
                  </div>
                )}
                <div className="position-relative">
                  <div
                    style={{
                      gap: "3rem",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {sortedUsers?.map((item, i) => (
                      <div>
                        <VideoCard
                          deleteVideos={deleteVideos}
                          freelancerDetail={freelancerDetail}
                          unselectAll={unselectAll}
                          setUnSelectAll={setUnSelectAll}
                          info={item}
                          selectedVideos={selectedVideos}
                          setSelectedVideos={setSelectedVideos}
                          selectAll={selectAll}
                          index={i}
                          isBulkSelect={isBulkSelect}
                          getFreelancerDetails={() => {
                            getFreelancerDetails();
                            getUserTimeLine(userId?.id);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {!userId.admin && (
          <div className={`pl-4 ${styles.timelineWrap} d-none d-lg-block`}>
            <div className={`${styles.timeline} p-3 h-100`}>
              <p className="txt-lg txt-primary mb-3 mt-0">Timeline</p>

              {!!statusList?.length &&
                statusList.map((item) => (
                  <div className={`${styles.status} mb-2 pb-2 txt-smd`}>
                    <div className="d-flex align-items-center">
                      <span
                        className={`${styles.statusIndicator} mr-2`}
                        style={{
                          background: item?.approval
                            ? "green"
                            : "var(--error-main)",
                        }}
                      ></span>{" "}
                      <span className="txt-grey">
                        {timelineFormatDate(item?.approval_on)}
                      </span>
                    </div>
                    <div
                      className="txt-success px-4 mt-1"
                      style={{
                        color: item?.approval ? "green" : "var(--error-main)",
                      }}
                    >
                      {item?.approval
                        ? `Approved by ${item?.approval_by}`
                        : `Rejected by ${item?.approval_by}`}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {open && (
          <EditFreelancer
            open={open}
            handleClose={handleClose}
            info={freelancerDetail}
            admin={userId.admin}
            getData={getFreelancerDetails}
          />
        )}
        <ConfirmDialog
          isPrompt={false}
          open={openConfirmDialog}
          onClose={setOpenConfirmDialog}
          onConfirm={onConfirmDialog}
          message={`Are you Sure you want to ${
            isActive ? "disable" : "enable"
          } the ${userId.admin ? "admin" : "contractor"}?`}
        />
        <ConfirmDialog
          isPrompt={false}
          open={openDialog}
          onClose={setOpenDialog}
          onConfirm={onConfirm}
          count={selectedVideos.length}
          isDeleteVideo={true}
        />
        <ConfirmDialog
          isPrompt={false}
          open={openDownloadDialog}
          onClose={setOpenDownlaodDialog}
          onConfirm={onDownloadConfirm}
          count={selectedVideos.length}
          isDownloadVideo={true}
        />
        <ConfirmDialog
          isPrompt={true}
          open={openDeletePrompt}
          onClose={setOpenDeletePrompt}
          message={`${
            totalDeletedVideos === 1
              ? `1 Video`
              : `${totalDeletedVideos} Videos`
          } deleted successfully`}
        />

        <ConfirmDialog
          isPrompt={false}
          open={openAccountDeletePrompt}
          onClose={setOpenAccountDeletePrompt}
          onConfirm={onAccountDeleteConfirm}
          message={"Are you sure you want to Delete?"}
        />
      </div>
    </div>
  );
};

export default FreeLancerInfo;
