import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DownloadGif from "assets/images/downloadgif.gif";
import DownloadTick from "assets/images/download_tick.svg";
import { formatBytes } from "utils/videoUtils";
import { Paper } from "@material-ui/core";


function areEqualNumbers(num1, num2) {
  const number1 = Number(num1);
  const number2 = Number(num2);

  return !isNaN(number1) && !isNaN(number2) && number1 === number2;
}

const CurrentDownloadBox = ({ label, min, max }) => {

  const MIN = formatBytes(min)
  const MAX = formatBytes(max)
  const IS_CURRENT = areEqualNumbers(min , max)

  return (
    <Paper variant="outlined" style={{width:'30%', minWidth: '300px'}}>
      <Stack
        direction="row"
        alignItems="center"
        gap={3}
        borderRadius={0.5}
        px={2}
        py={1}
        flexWrap={'wrap'}
      >
        <Typography variant="subtitle1" fontSize={".9rem"} fontWeight={600} style={{ flex: 1, wordBreak: 'break-word' }}>
          {label}
        </Typography>
        <Typography fontSize={".7rem"}>
          {MIN || "--"}/
          <span style={{ color: "red" }}>{MAX || "--"}</span>
        </Typography>
        {IS_CURRENT ? <img src={DownloadTick} width={"18px"} alt="" /> : <img src={DownloadGif} width={"18px"} alt="" />}
      </Stack>
    </Paper>
  );
};

export default CurrentDownloadBox;
