import { useState, useEffect, useContext } from "react";
import styles from "./freelancer.module.scss";
import { InputField, UserInfoCard, ConfirmDialog } from "components";
import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";

import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";
import AddFreelancer from "./add-freelancer/add-freelancer";
import { Paper, Typography } from "@material-ui/core";
import ButtonWithIcon from "components/IconButton/icon-button";
import { Stack } from "@mui/material";
import { checkBoolean } from "utils/videoUtils";
import FreelancerSortPopover from "./freelancer-sort-popover/FreelancerSortPopover";
import FreelancerFilterPopover from "./freelancer-filter-opover/FreelancerFilterPopover";
import moment from "moment";
import { getErrorMessage } from "utils/validator";

const FreeLancer = () => {
  const {
    dispatch,
    state: { isAdmin, showLoader },
  } = useContext(GlobalContext);
  const IS_ADMIN = checkBoolean(isAdmin);

  const [open, setOpen] = useState(false);
  const [freeLancerDataCopy, setFreeLancerDataCopy] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason, showPrompt) => {
    setOpen(false);
  };

  const getFreelancerData = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } = await AuthServices.getFreelancerData();
      if (data?.length) {
        setFreeLancerDataCopy([...data]);
      }
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      getErrorMessage(err);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  useEffect(() => {
    getFreelancerData();
  }, []);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  //filter
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleOpenFilterPopover = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  //filter

  //Sort
  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleOpenSortPopover = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  //Sort

  //implementaion

  const [sortCriteria, setSortCriteria] = useState("");
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  };

  const handleFilterChange = (filter) => {
    setFilterCriteria((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((item) => item !== filter);
      } else {
        return [...prev, filter];
      }
    });
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
  };

  function roleFilteredUsers(data, inp) {
    const activePresent = inp.includes("active");
    const inactivePresent = inp.includes("inactive");
    const qaPresent = inp.includes("qa");
    const adminPresent = inp.includes("admin");

    return data.filter((item) => {
      let statusCondition = true;
      let qaUserCondition = true;

      // Apply status condition
      if (activePresent && !inactivePresent) {
        statusCondition = item.status === true;
      } else if (inactivePresent && !activePresent) {
        statusCondition = item.status === false;
      }

      // Apply qa_user condition
      if (qaPresent && !adminPresent) {
        qaUserCondition = item.qa_user === true;
      } else if (adminPresent && !qaPresent) {
        qaUserCondition = item.qa_user === false;
      }

      // Return true if both conditions are satisfied
      return statusCondition && qaUserCondition;
    });
  }

  const filteredUsers = roleFilteredUsers(
    freeLancerDataCopy,
    filterCriteria
  ).filter(
    (user) =>
      user.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user.phone?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user.zipcode?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user.address?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      user.account_id?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const sortedUsers = filteredUsers.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (sortCriteria === "dec") {
      return nameB.localeCompare(nameA);
    } else if (sortCriteria === "acc") {
      return nameA.localeCompare(nameB);
    } else if (sortCriteria === "accd") {
      return moment(a.doj).diff(moment(b.doj));
    } else if (sortCriteria === "decd") {
      return moment(b.doj).diff(moment(a.doj));
    }
    return 0;
  });
  // implementaion

  return (
    <div className="pt-4">
      <Paper
        style={{
          borderRadius: "1rem",
          boxShadow:
            "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
          border: "none",
        }}
        variant="outlined"
        className={`${styles.freelanceHeader} d-flex justify-content-between align-items-center flex-lg-row flex-column`}
      >
        <Typography
          variant="h4"
          style={{ fontSize: "2.3rem", fontWeight: "600" }}
        >
          Contractors
        </Typography>
        <div>
          <Stack direction={"row"} gap={"1rem"} pr={".5rem"}>
            <ButtonWithIcon
              disableRipple
              startIcon={<SortIcon />}
              onClick={handleOpenSortPopover}
            >
              Sort
            </ButtonWithIcon>
            <FreelancerSortPopover
              anchorEl={sortAnchorEl}
              handleClose={handleSortClose}
              handleSortChange={handleSortChange}
              check={sortCriteria}
            />

            {IS_ADMIN && (
              <ButtonWithIcon
                disableRipple
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                Add Contractor
              </ButtonWithIcon>
            )}

            <InputField
              value={searchQuery}
              variant="filled"
              placeholder="Search by name or id"
              withAdronment={true}
              onChange={(event) => handleSearchChange(event.target.value)}
            />

            <ButtonWithIcon
              disableRipple
              startIcon={<FilterIcon />}
              onClick={handleOpenFilterPopover}
            >
              Filter
            </ButtonWithIcon>
            <FreelancerFilterPopover
              anchorEl={filterAnchorEl}
              handleClose={handleFilterClose}
              handleFilterChange={handleFilterChange}
              check={filterCriteria}
            />
          </Stack>
        </div>
      </Paper>
      <div
        className={`${styles.freelancerCardWrapper} py-4 d-flex flex-wrap justify-content-center justify-content-md-start`}
      >
        {!!sortedUsers?.length &&
          sortedUsers.map((item) => (
            <div className="px-3 my-0">
              <UserInfoCard
                item={item}
                admin={false}
                getData={getFreelancerData}
              />
            </div>
          ))}
        {!sortedUsers?.length && !showLoader && (
          <p className="txt-xlg txt-primary flex-grow-1 text-center">
            There are No Contractors!
          </p>
        )}
      </div>
      {open && (
        <AddFreelancer
          open={open}
          handleClose={handleClose}
          getData={getFreelancerData}
        />
      )}
    </div>
  );
};

export default FreeLancer;
