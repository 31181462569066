import { useState, useContext, useEffect } from "react";
import styles from "./video-list.module.scss";
import avatar from "assets/images/default.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import NoVideo from "assets/images/video-placeholder.svg";
import { GlobalContext, actions } from "context";

import VideoCard from "app/freelancer-info/video-card/video-card";
import { Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import GalleryVideoPlayer from "../gallery-video-player/gallery-video-player";
import { simulatePostApi } from "app/downloadfn";

const UserVideoList = ({ data, getData, refresh }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const videoID = queryParams.get("video");
  const UserName = queryParams.get("name");
  const selectedVideo = data?.videos?.filter((item) => item?.id === videoID);
  const { name } = selectedVideo?.[0] || {};
  const { dispatch } = useContext(GlobalContext);

  const [freelancerDetail, setFreelancerDetail] = useState();

  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isBulkSelect, setIsBulkSelect] = useState(false);

  const [unselectAll, setUnSelectAll] = useState(false);

  const [selectedVideoURL, setSelectedVideoURL] = useState("");

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const deleteVideos = async (url) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      storeHandler(actions.SHOW_LOADER, false);
      refresh();
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const generateVideoUrl = async () => {
    if (!selectedVideo) {
      console.log(selectedVideo?.[0], "selectedVideo?.[0] is empty");
      return;
    }
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const res_url = await simulatePostApi(selectedVideo?.[0]);
      setSelectedVideoURL(res_url?.[0]);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      console.log(error);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  // useEffect(() => {
  //   if (videoID) {
  //     generateVideoUrl();
  //   }
  // }, [videoID]);

  return (
    <div className="pt-0" style={{ width: "100%", display: "flex" }}>
      <div className="d-flex flex-wrap">
        <div className={`flex-grow-1 ${styles.infoWrap}`}>
          <Stack
            direction="row"
            gap={"1rem"}
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() =>
              getData([
                { key: "name", value: data?.user_name },
                { key: "user_id", value: data?.user_id },
              ])
            }
          >
            <img
              src={data?.profile || avatar}
              width="60px"
              height="60"
              style={{ borderRadius: "50%", border: "1px solid black" }}
              alt="user"
            />
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              {data?.user_name}
            </Typography>
            {!UserName && (
              <ArrowForwardIosIcon style={{ marginLeft: "1rem" }} />
            )}
          </Stack>
          <div className="d-flex flex-wrap">
            {!data?.videos?.length && (
              <div className="text-center pb-3">
                <img
                  src={NoVideo}
                  alt="no video"
                  className={`${styles.noVideo}`}
                />
                <p className="txt-lg txt-primary my-0">
                  Oops There is no Recorded Video
                </p>
              </div>
            )}
            <div className="position-relative">
              {!!data?.videos?.length && (
                <div className={`${styles.videoSectionBody}`}>
                  <div
                    className={`${styles.videoSection} py-4 d-flex justify-content-between flex-grow-1 flex-wrap p-0`}
                  >
                    <div
                      className={`${styles.videoCardSection} d-flex flex-wrap justify-content-lg-start justify-content-center px-0`}
                    >
                      {data?.videos?.map((item, i) => (
                        <div className="pr-5 mb-5">
                          <div
                            className={`${
                              videoID == item?.id && styles.videoSelected
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              getData([
                                { key: "name", value: data?.user_name },
                                { key: "user_id", value: data?.user_id },
                                { key: "video", value: item?.id },
                              ]);
                            }}
                          >
                            <VideoCard
                              deleteVideos={deleteVideos}
                              freelancerDetail={freelancerDetail}
                              unselectAll={unselectAll}
                              setUnSelectAll={setUnSelectAll}
                              info={item}
                              selectedVideos={selectedVideos}
                              setSelectedVideos={setSelectedVideos}
                              selectAll={selectAll}
                              index={i}
                              isBulkSelect={isBulkSelect}
                              getFreelancerDetails={refresh}
                              showPlayer={false}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* {videoID && selectedVideoURL && (
        <div
          className="pr-3"
          style={{
            width: "min-content",
            marginTop: "60px",
            marginLeft: "auto",
          }}
        >
          <div style={{ position: "relative" }} className="pt-4">
            <Typography
              variant="h5"
              style={{ position: "absolute", top: "-.6rem" }}
            >
              {name}
            </Typography>
            <GalleryVideoPlayer
              data={selectedVideo?.[0] || {}}
              video={selectedVideoURL}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default UserVideoList;
