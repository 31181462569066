import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactComponent as CurrentDownloadIcon } from "assets/images/videodownload.svg";
import { formatBytes, getDataFromLocalStorage } from "utils/videoUtils";
import moment from "moment";
import { Typography } from "@mui/material";
import { useTheme } from "@material-ui/core";

const convertMemoryToBytes = (memory) => {
  // Regular expression to parse the memory string
  const match = memory.match(/^(\d+)\s*(KB|MB|GB)?$/i);

  if (!match) return memory; // Return original if no match

  const value = parseFloat(match[1]);
  const unit = match[2]?.toUpperCase();

  // Convert value to bytes based on the unit
  switch (unit) {
    case "GB":
      return value * 1024 * 1024 * 1024;
    case "MB":
      return value * 1024 * 1024;
    case "KB":
      return value * 1024;
    default:
      return value; // Assuming the value is already in bytes or an invalid unit
  }
};

function areEqualNumbers(num1, num2) {
  const number1 = Number(num1);
  const number2 = Number(num2);

  return !isNaN(number1) && !isNaN(number2) && number1 === number2;
}

const DownloadBox = ({ data }) => {
  const processData = (data) => {
    return {
      ...data,
      memory: convertMemoryToBytes(data.memory),
    };
  };

  data = data?.map((item) => processData(item));

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const totalMemory = data?.reduce((total, item) => total + item.memory, 0);
  const totalSize = data?.reduce((total, item) => total + item.loaded, 0);
  const { group_id, file_name: f_name } = data?.[0] || {};
  const totalDownloadedVideo = data?.filter(
    (item) => areEqualNumbers(item.total, item.loaded)
  );
  const download_completed = data?.every((item) => item.total === item.loaded);
  const not_started = data?.some((item) => "total" in item && "loaded" in item);
  const totalVideo = data?.length || 0;
  const date = moment(group_id).format("DD MMM YYYY");
  const time = moment(group_id).format("h:mm a");
  const current_download = getDataFromLocalStorage("current_download");
  let isActiveBox = current_download?.group_id === data[0]?.group_id;

  const calculateLoadedPercentage = () => {
    let totalSum = 0;
    let loadedSum = 0;

    data?.forEach((item) => {
      if (typeof item.memory === "number") {
        totalSum += item.memory;
      }

      if (typeof item.loaded === "number") {
        loadedSum += item.loaded;
      }
    });

    let percentageLoaded = (loadedSum / totalSum) * 100;

    return percentageLoaded;
  };

  let file_name, loaded, total;

  file_name = !not_started
    ? f_name
    : download_completed === true
    ? "Download Completed"
    : current_download.file_name;
  loaded = download_completed ? totalSize : current_download.loaded;
  total = download_completed ? totalMemory : current_download.total;

  return (
    <Stack
      direction={"row"}
      borderBottom={
        !isActiveBox && not_started && "1px solid var(--text-disabled)"
      }
      sx={{
        background: (isActiveBox || !not_started) && !isDark && "#ffd8d8",
      }}
      justifyContent={"space-between"}
      alignItems={"center"}
      p={1.5}
      px={3}
      color={download_completed && not_started && "gray"}
    >
      <Box position={"relative"} sx={{ width: "5%", minWidth: "5rem" }}>
        <CircularProgress
          variant="determinate"
          value={100}
          sx={{
            display: download_completed && not_started ? "none" : "block",
            color: "lightgray",
            position: "absolute",
            top: "-3px",
            left: "-10px",
          }}
          size={"35px"}
        />
        <CircularProgress
          variant="determinate"
          value={calculateLoadedPercentage()}
          color="error"
          sx={{
            display: download_completed && not_started ? "none" : "block",
            position: "absolute",
            top: "-3px",
            left: "-10px",
          }}
          size={"35px"}
        />

        <CurrentDownloadIcon
          width={"15px"}
          fill={download_completed && not_started ? "lightgray" : "#C81E1E"}
          style={{ position: "relative", top: "3px" }}
        />
      </Box>
      <Box sx={{ width: "20%", minWidth: "15rem" }}>
        <Typography fontWeight={""} fontSize={".9rem"}>
          {file_name || "Download Completed"}
        </Typography>
      </Box>
      <Stack
        sx={{ width: "30%", minWidth: "25rem" }}
        direction={"row"}
        alignItems={"center"}
        gap={1}
      >
        <Box minWidth={"60%"} mr={1.5}>
          <LinearProgress
            sx={{ height: "3px" }}
            variant="determinate"
            color="error"
            value={(loaded / total) * 100 || 0}
          />
        </Box>
        <Box>
          <Typography fontWeight={""} fontSize={".8rem"}>
            {formatBytes(loaded) || "-"}/{formatBytes(total) || "-"}
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ width: "10%", minWidth: "5rem" }}>
        <Typography fontWeight={""} fontSize={".8rem"}>
          {date}
        </Typography>
      </Box>
      <Box sx={{ width: "10%", minWidth: "5rem" }}>
        <Typography fontWeight={""} fontSize={".8rem"}>
          {time}
        </Typography>
      </Box>
      <Box sx={{ width: "10%", minWidth: "5rem" }}>
        <Typography fontWeight={""} fontSize={".8rem"}>
          {totalDownloadedVideo?.length}/{totalVideo} Videos
        </Typography>
      </Box>
    </Stack>
  );
};

export default DownloadBox;
