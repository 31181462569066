import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  Stack,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./FreelancerSortPopover.scss";
import { Checkbox } from "components";
import { ReactComponent as ArrowDownIcon } from "assets/images/filter_down_arrow.svg";
import { ReactComponent as ArrowUpIcon } from "assets/images/filter_up_arrow.svg";

function FreelancerSortPopover({
  anchorEl,
  handleClose,
  handleSortChange,
  check,
}) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openCollapseDate, setOpenCollapseDate] = useState(false);

  const handleSearchChange = (value) => {
    handleSortChange(value);
  };

  const handleToggleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  const handleToggleCollapseDate = () => {
    setOpenCollapseDate(!openCollapseDate);
  };

  return (
    <Box className="Download-popover-root">
      <Popover
        id={"freelancer-sort-popover"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: "20rem",
            },
          },
        }}
      >
        <div
          className="popover-content"
          style={{ minWidth: 180, marginBottom: "2px" }}
        >
          <Typography
            variant="subtitle1"
            fontSize={".9rem"}
            sx={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              cursor: "pointer",
              py: 0.5,
              px: 1,
            }}
            onClick={handleToggleCollapse}
          >
            Name
            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Typography>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <List>
              <ListItem style={{ cursor: "pointer" }}>
                <ListItemText
                  primary={
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px={".8rem"}
                      >
                        <Stack direction="row" gap={2} alignItems="center">
                          <Typography variant="body2">Ascending</Typography>
                          <ArrowUpIcon height={"15px"} />
                        </Stack>
                        <Checkbox
                          size="small"
                          checked={check === "acc"}
                          onChange={() => handleSearchChange("acc")}
                        />
                      </Stack>
                    </>
                  }
                />
              </ListItem>

              <ListItem style={{ cursor: "pointer" }}>
                <ListItemText
                  primary={
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px={".8rem"}
                      >
                        <Stack direction="row" gap={1} alignItems="center">
                          <Typography variant="body2">Descending</Typography>
                          <ArrowDownIcon height={"15px"} />
                        </Stack>
                        <Checkbox
                          size="small"
                          checked={check === "dec"}
                          onChange={() => handleSearchChange("dec")}
                        />
                      </Stack>
                    </>
                  }
                />
              </ListItem>
            </List>
          </Collapse>
        </div>
        <div className="popover-content" style={{ minWidth: 180 }}>
          <Typography
            variant="subtitle1"
            fontSize={".9rem"}
            sx={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              cursor: "pointer",
              py: 0.5,
              px: 1,
            }}
            onClick={handleToggleCollapseDate}
          >
            Date
            {openCollapseDate ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </Typography>
          <Collapse in={openCollapseDate} timeout="auto" unmountOnExit>
            <List>
              <ListItem style={{ cursor: "pointer" }}>
                <ListItemText
                  primary={
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px={".8rem"}
                      >
                        <Stack direction="row" gap={2} alignItems="center">
                          <Typography variant="body2">Ascending</Typography>
                          <ArrowUpIcon height={"15px"} />
                        </Stack>
                        <Checkbox
                          size="small"
                          checked={check === "accd"}
                          onChange={() => handleSearchChange("accd")}
                        />
                      </Stack>
                    </>
                  }
                />
              </ListItem>

              <ListItem style={{ cursor: "pointer" }}>
                <ListItemText
                  primary={
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px={".8rem"}
                      >
                        <Stack direction="row" gap={1} alignItems="center">
                          <Typography variant="body2">Descending</Typography>
                          <ArrowDownIcon height={"15px"} />
                        </Stack>
                        <Checkbox
                          size="small"
                          checked={check === "decd"}
                          onChange={() => handleSearchChange("decd")}
                        />
                      </Stack>
                    </>
                  }
                />
              </ListItem>
            </List>
          </Collapse>
        </div>
      </Popover>
    </Box>
  );
}

export default FreelancerSortPopover;
