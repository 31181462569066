import React, { useContext, useState } from "react";
import styles from "./OuterCommentStyle.module.scss";

import { Button, Snackbar, TextField } from "components";
import { InputAdornment, makeStyles, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { convertSecondsToHMS } from "utils/videoUtils";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: ".5rem",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: ".5rem",
    },
  },
}));

const roundValue = (time) => {
  const validTime = Number(time);
  if (!isNaN(validTime) && validTime >= 0) {
    const roundedTime = Math.round(validTime);
    return roundedTime;
  } else {
    console.warn("Invalid time value provided:", time);
  }
};

const sortCommentsByDate = (comments) => {
  if (!Array.isArray(comments) || comments.length === 0) {
    return []; 
  }

  return [...comments].sort((a, b) => {
    const dateA = moment(a.comment_date);
    const dateB = moment(b.comment_date);
    return dateA - dateB;
  });
};

function OuterComment({
  approval,
  id,
  comments,
  videRef,
  handleSetComment,
  isVideoPause,
  name,
  getAllComment,
  refresh
}) {

  const { dispatch } = useContext(GlobalContext);
  const classes = useStyles();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleApproveRejectVide = async (status) => {
    storeHandler(actions.SHOW_LOADER, true);

    try {
      await AuthServices.changeVideoStatus({ id, status });
      storeHandler(actions.SHOW_LOADER, false);
      videRef?.current?.pause()
      refresh()
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleAddComment = async () => {
    const currentTime = videRef?.current?.currentTime();
    if (comment.length === 0) {
      setErrorMessage("Comment cannot be empty.");
      return;
    } else if (comment.length > 300) {
      setErrorMessage("Comment cannot exceed 300 characters.");
      return;
    } else {
      setErrorMessage("");
    }
    storeHandler(actions.SHOW_LOADER, true);

    try {
      const payload = {
        video_id: id,
        time_sec: roundValue(currentTime),
        comment,
      };
      await AuthServices.addVideoComment(payload);
      setComment("");
      storeHandler(actions.SHOW_LOADER, false);
      getAllComment()
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const seekToTime = (time) => {
    if (videRef.current) {
      videRef?.current?.currentTime(time);
      videRef?.current?.pause();
      console.log(`Seeked to ${time} seconds`);
    }
  };

  return (
    <div className={`${styles.commentBody}`}>
      <div className="p-1">
        <TextField
          placeholder="Add Comment.."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onFocus={() => videRef?.current?.pause()}
          classes={{ root: classes.root }}
          variant="outlined"
          fullWidth
          InputProps={{
            disableUnderline: true,
            style: {
              backgroundColor: "#ededed",
            },
            startAdornment: (
              <InputAdornment position="start">
                {isVideoPause && (
                  <Button
                    variant="contained"
                    disableRipple={true}
                    style={{
                      padding: ".3rem",
                      fontSize: ".8rem",
                      fontWeight: "200",
                      backgroundColor: "white",
                      color: "black",
                      boxShadow: "none",
                      cursor: "not-allowed",
                    }}
                  >
                    {convertSecondsToHMS(
                      videRef?.current?.currentTime(),
                      false
                    )}
                  </Button>
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  disabled={!isVideoPause}
                  onClick={handleAddComment}
                  variant="contained"
                  disableRipple={true}
                  style={{
                    padding: ".3rem",
                    fontSize: ".8rem",
                    fontWeight: "200",
                    backgroundColor: "black",
                    color: "white",
                    boxShadow: "none",
                  }}
                >
                  Post
                </Button>
              </InputAdornment>
            ),
          }}
          helperText={errorMessage}
        />
      </div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={"1rem"}
        className=" my-3"
      >
        {(approval === true || approval === null) && (
          <Button
            disabled={approval === true}
            onClick={(e) => {
              handleApproveRejectVide(true);
            }}
            style={{
              padding: ".3rem 1rem",
              borderRadius: "2rem",
              fontSize: ".8rem",
              fontWeight: "200",
              backgroundColor: "black",
              color: "white",
              boxShadow: "none",
              width: "5rem",
            }}
          >
            {approval === true ? "Approved" : "Approve"}
          </Button>
        )}

        {(approval === false || approval === null) && (
          <Button
            disabled={approval === false}
            variant="contained"
            onClick={() => handleApproveRejectVide(false)}
            style={{
              padding: ".3rem 1rem",
              width: "5rem",
              borderRadius: "2rem",
              fontSize: ".8rem",
              fontWeight: "200",
              backgroundColor: "var(--error-main)",
              color: "white",
              boxShadow: "none",
            }}
          >
            {approval === false ? "Rejected" : "Reject"}
          </Button>
        )}
      </Stack>

      <Stack
        direction="column"
        maxHeight={"15rem"}
        style={{ overflowY: "scroll" }}
      >
        {!!comments?.length && (
          <Stack className={`${styles.allComment}`} >
            {sortCommentsByDate(comments)?.map(({ id, comment, time_sec }) => (
              <Stack
                className={`${styles.comment}`}
                key={id}
                onClick={() => seekToTime(time_sec)}
              >
                <Typography variant="caption">
                  {convertSecondsToHMS(time_sec, false)}
                </Typography>
                <Typography variant="caption">{comment}</Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </div>
  );
}

export default OuterComment;
